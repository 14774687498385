.connected-searchers{
    text-align: center;
    .count{
        height: 68px;
        width: 68px;
        border: 3px solid #4889ff;
        border-radius: 68px;
        margin: 16px auto 0;
        line-height: 65px;
        font-size: 26px;
        font-weight: 400;
    }
}