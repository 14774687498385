.broadcaster-requests{
    table{
        table-layout: fixed;
    }
    table-layout: fixed;
    tr:last-child{
        td,th{
            border-bottom: none;
        }
    }
    td{
        padding: 10px 0;
        font-weight: bold;
    }
    td{
        font-size: 18px;
        &:nth-child(2){
            color: #63a463;
        }
        &:nth-child(3){
            color: #d3b071;
        }
        &:nth-child(4){
            color: #4889ff;
        }
    }
    th{
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        h2{
            color: rgba(0, 0, 0, 0.87);
        }
    }
}