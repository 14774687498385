.searchers-stats {
  table {
    table-layout: fixed;
  }

  table-layout: fixed;

  tr:last-child {
    td, th {
      border-bottom: none;
    }
  }

  td {
    padding: 10px 0;
    font-weight: bold;
  }

  td {
    font-size: 18px;

    &:nth-child(1) {
      color: #63a463;
    }

    &:nth-child(2) {
      color: #d3b071;
    }

    &:nth-child(3).connected {
      color: #4889ff;
    }

    &:nth-child(3).disconnected {
      color: rgb(198, 98, 98);
    }
  }

  th {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);

    h2 {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
