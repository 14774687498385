.mev-table-container {
  td {
    font-size: 14px;
    white-space: nowrap;
  }

  tr:not(:last-child):hover {
    background-color: #ffff99;
  }

  thead {
    th:not(:first-child) {
      border-left: 2px solid rgba(244, 244, 244, 1);
    }
    tr:first-child {
      th {
        border-bottom: none;
        padding: 0 0 0 8px;
        line-height: 12px;
      }
    }
    tr:last-child {
      th {
        top: 12px;
      }
    }
  }

  th {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }

  .kolibrio-unique-icon {
    position: relative;
    top: 2px;
    .kolibrio-unique-icon-disabled {
      opacity: 0.5;
      transition: 0.3s;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
