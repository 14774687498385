.priority-fee-table{
  margin-top: 16px;
  .priority-fee-collapsible-row{
    td, th {
      // padding-top: 0;
      // padding-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      line-height: 27px;
      &.green{
        font-size: 16px;
        // line-height: 24px;
      }
    }
    th{
      font-size: 16px;
      font-weight: bold;
    }
  }
  .priority-fee-collapsible-row-child{
    >td,
    >th {
      border-bottom: none;
    }
    .priority-fee-collapsible-row{
      .MuiIconButton-root{
        display: none;
      }
      td, th {
        font-size: 14px;
        line-height: 16px;
        background: #fafafa;
        &.green{
          font-size: 14px;
        }
      }
    }
  }
  .green{
    color: #63a463;
  }
  .yellow{
    color: #d3b071;
  }
}