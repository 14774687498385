.nav-bar{
    .logo-box{
        position: relative;
        padding-bottom: 8px;
        margin-top: 4px;
        margin-right: 50px;

        
        span {
            position: absolute;
            left: 88px;
            bottom: 0;
            font-size: 16px;
            font-weight: bold;
            text-decoration: none;
            line-height: 16px;
            color: #000000;
        }
    }

    .nav-link{
        font-size: 16px;
        text-decoration: none;
        .MuiButton-text{
            font-weight: bold;
        }
    }
}
    #menu-appbar{
        a{
            text-decoration: none;
            font-size: 18px;
            padding: 3px 12px;
            display: block;
            color: #000000;
        }
    }